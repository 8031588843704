/* Root styles */
html,
body {
  margin: 0;
}

.App {
  text-align: center;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  background: linear-gradient(233deg, #ebd862, #ff7176);
  background-size: 400% 400%;
  font-family: "Arial", Arial, monospace;
  animation: AnimationName 15s ease infinite;
}

/* Animations */
@keyframes AnimationName {
  0% {
    background-position: 0% 81%;
  }
  50% {
    background-position: 100% 20%;
  }
  100% {
    background-position: 0% 81%;
  }
}

@keyframes pulse {
  0% {
    r: 8;
    opacity: 1;
  }
  50% {
    r: 12;
    opacity: 0.5;
  }
  100% {
    r: 13;
    opacity: 0;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Animated Container */
.animated-container {
  position: relative;
  height: 200px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.animated-item {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.3s ease, transform 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.animated-item.show {
  opacity: 1;
  transform: translateY(0);
  z-index: 1;
  overflow: scroll;
}

.animated-item.hide {
  opacity: 0;
  transform: translateY(-20px);
  z-index: 0;
}

/* Nutrition Summary */
.nutrition-container {
  position: relative;
  padding: 20px;
  max-height: 30%;
}

.nutrition-summary {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: white;
  border-radius: 15px;
  padding: 20px;
  cursor: pointer;
  font-size: larger;
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: transparent;
  max-height: 50%;
}

.list-icon {
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  transition: background-color 0.3s ease;
  z-index: 1000;
}

.list-icon:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.nutrition-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3rem;
  pointer-events: auto;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.nutrition-list-icon {
  width: 1.5rem;
  height: 1.5rem;
  pointer-events: auto;
  align-self: flex-end;
}

.nutrition-item h1 {
  font-size: 10vw;
  margin: 0;
  opacity: 0.8;
}

.nutrition-item h2 {
  font-size: 8vw;
  margin: 0;
  opacity: 0.8;
  width: 3ch;
  text-align: left;
}

/* Meal Form */
#meal-form {
  display: flex;
  max-width: 600px;
  max-height: 40px;
  align-self: center;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  gap: 15px;
}

#meal-input {
  flex-grow: 1;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 5px 0 0 5px;
}

#meal-form button {
  padding: 10px 15px;
  font-size: 16px;
  border: none;
  background-color: #fad0c4;
  color: white;
  cursor: pointer;
  border: 1px solid white;
}

#meal-form button:last-child {
  border-radius: 0 5px 5px 0;
}

/* Itemized List */
.itemized-list {
  font-family: "Arial", Arial, monospace;
  max-width: 600px;
  border-radius: 15px;
  color: white;
  padding: 20px;
  opacity: 1;
  transition: opacity 0.3s ease-out;
  overflow-y: auto;
  flex-grow: 1;
  margin: 0 auto;
  width: calc(100% - 40px);
  max-height: calc(100vh - 300px);
}

.itemized-list-container {
  opacity: 1;
  pointer-events: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: scroll;
}

.itemized-list-container ul {
  list-style-type: none;
  color: white;
  padding: 0;
}

.itemized-list-container li {
  pointer-events: auto;
  display: flex;
  gap: 15px;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  height: 2rem;
  text-wrap: nowrap;
  font-size: small;
}

.itemized-list-container button {
  background-color: #fad0c4;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
}

/* Date Selector */
.date-selector {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.date-button {
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  border-radius: 5px;
  padding: 5px 10px;
  transition: background-color 0.3s ease, color 0.3s ease;
}


.date-display {
  color: white;
  font-size: 1rem;
}

/* Chart Container */
.chart-container {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.3s ease, transform 0.3s ease;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end; /* Aligns the chart at the bottom */
  flex-grow: 1;
  margin: 0 auto;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.chart-container svg {
  width: 100%;
  height: 100%;
  max-width: 600px;
}

.chart-container circle {
  transition: cx 0.5s ease, cy 0.5s ease;
  animation: fadeInUp 0.5s ease forwards;
  animation-delay: calc(var(--index) * 0.1s);
}

.chart-container path {
  transition: d 0.5s ease;
}

.pulse {
  animation: pulse 2s infinite;
}

/* Media Queries */
@media (max-width: 1200px) {
  .nutrition-item h1 {
    font-size: 8rem;
  }
  .nutrition-item h2 {
    font-size: 6rem;
  }
}

@media (max-width: 768px) {
  .nutrition-item h1 {
    font-size: 6rem;
  }
  .nutrition-item h2 {
    font-size: 4rem;
  }
}

@media (max-width: 480px) {
  .nutrition-item h1 {
    font-size: 4rem;
  }
  .nutrition-item h2 {
    font-size: 2rem;
  }
  #meal-input {
    padding: 10px 5px;
  }
  #meal-form button {
    padding: 10px 5px;
  }
}

@media (min-height: 1000px) {
  /* Nutrition Summary */
.nutrition-container {
  max-height:0%;
}
}